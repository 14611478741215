<template>
  <div class="row-bg">
    <div class="container">
      <div class="warpper">
        <div class="title">How it Works</div>
        <div class="desc">
          Our car valuation and sale services follow a simple 4-step process to
          help our clients get paid for their cars faster. Explore below the
          method making Selling Your Car Australia’s preferred vehicle trading
          service.
        </div>
        <div class="row-info">
          <div class="item">
            <img src="~@/assets/images/laplog.png" alt="" />
            <div class="item-title">Request a Quote</div>
            <div class="item-desc">
              Contact us by email or submit an online form to request a quote
              for your vehicle.
            </div>
          </div>
          <div class="item">
            <img src="~@/assets/images/contract.png" alt="" />
            <div class="item-title">Negotiate the Price</div>
            <div class="item-desc">
              What’s my car worth? We’ll offer you a dealer-beating price for
              your vehicle.
            </div>
          </div>
          <div class="item">
            <img src="~@/assets/images/hand.png" alt="" />
            <div class="item-title">Receive Your Money</div>
            <div class="item-desc">
              Once the price is agreed, your money will arrive within 24-hours.
            </div>
          </div>
          <div class="item">
            <img src="~@/assets/images/car.png" alt="" />
            <div class="item-title">That’s a Wrap!</div>
            <div class="item-desc">
              And that’s all it takes – get cash for unwanted cars – easily and
              quickly.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="less">
.row-bg {
  width: 100%;
  background: #fff;
  .warpper {
    padding: 120px 0;
    box-sizing: border-box;
    .title {
      font-size: 40px;
      font-family: Ubuntu-Bold, Ubuntu;
      font-weight: bold;
      color: #062748;
      line-height: 47px;
      text-align: center;
    }
    .desc {
      font-size: 20px;
      font-family: Ubuntu-Regular, Ubuntu;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.7200000286102295);
      line-height: 22px;
      text-align: center;
      margin-top: 16px;
    }
    .row-info {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      .item {
        width: 300px;
        img {
          width: 120px;
          height: 120px;
          margin: 0 auto;
        }
        .item-title {
          font-size: 24px;
          font-family: Ubuntu-Bold, Ubuntu;
          font-weight: bold;
          color: #222222;
          line-height: 28px;
          text-align: center;
          margin-top: 12px;
        }
        .item-desc {
          font-size: 16px;
          font-family: Ubuntu-Regular, Ubuntu;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.7200000286102295);
          line-height: 19px;
          text-align: center;
          margin-top: 12px;
        }
      }
    }
  }
}
@media only screen and (max-width: 690px) {
  .row-bg {
    .warpper {
      padding: 40px 0;
      .title {
        font-size: 24px;
        line-height: 28px;
      }
      .desc {
        font-size: 12px;
        line-height: 14px;
      }
      .row-info {
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        .item {
          width: 335px;
          margin-top: 40px;
          img {
            width: 80px;
            height: 80px;
          }
          .item-title {
            font-size: 16px;
            line-height: 19px;
          }
          .item-desc {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1050px) and (min-width: 690px) {
  .row-bg {
    .warpper {
      padding: 40px 0;
      .title {
        font-size: 24px;
        line-height: 28px;
      }
      .desc {
        font-size: 12px;
        line-height: 14px;
      }
      .row-info {
        margin-top: 0;
        flex-direction: column;
        align-items: center;
        .item {
          width: 335px;
          margin-top: 40px;
          img {
            width: 80px;
            height: 80px;
          }
          .item-title {
            font-size: 16px;
            line-height: 19px;
          }
          .item-desc {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }
  }
}
</style>
