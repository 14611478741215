<template>
  <div class="recommend">
    <div class="warpper">
      <div class="swiper">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #5f6bc1">D</div>
                <div class="info">
                  <div class="name">Darren M.</div>
                  <div class="title">Ballarat, VIC</div>
                </div>
              </div>
              <div class="desc">
                I was really impressed with how quick and efficient the process
                was. Their offer on my car was amazing, in fact it was more than
                I paid when I bought it new. we agreed on a final offer, I
                mailed them the title, the money appeared in my account, and
                someone came and picked it up. I was amazed and how quickly and
                smoothly it went. Thank you!
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #6d9e42">K</div>
                <div class="info">
                  <div class="name">Kamar H.</div>
                  <div class="title">Brentwood, WA</div>
                </div>
              </div>
              <div class="desc">
                I sold my 2019 Tesla Model 3 Standard Plus to
                sellingyourcar.com.au . They gave me the best price out of
                several online quotes. The process was simple and they kept
                their word every step of the way. I highly recommend
                sellingyourcar.com.au to anyone looking to sell their car.
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #e9497d">J</div>
                <div class="info">
                  <div class="name">James Y.</div>
                  <div class="title">Mascot, NSW</div>
                </div>
              </div>
              <div class="desc">
                Had a really good experience selling my car to this company.
                They were responsive and efficient. The process was
                straightforward and easy to follow. And they offered really fair
                price for the car. Highly recommend!!
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #5f6bc1">K</div>
                <div class="info">
                  <div class="name">Kaylene M.</div>
                  <div class="title">Mudgeeraba, QLD</div>
                </div>
              </div>
              <div class="desc">
                I sold my Lexus 2019 RX350L and they offer the best price. I
                requested at least 4 more quotes. The process ended smoothly in
                less than one month as expected.
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #6d9e42">T</div>
                <div class="info">
                  <div class="name">Twisha A.</div>
                  <div class="title">Mount Barker, SA</div>
                </div>
              </div>
              <div class="desc">
                First time selling a car online. From Start, to Bill, and Toby,
                these people are wonderful to deal with. Very knowledgeable,
                honest, plus they were quick to get me my quote for my car,as
                well as the money. I would definitely recommend them to
                everyone.
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #e9497d">E</div>
                <div class="info">
                  <div class="name">Erik P.</div>
                  <div class="title">Sebastopol, VIC</div>
                </div>
              </div>
              <div class="desc">
                Awesome staff and great customer services! Darren and Harrison
                were very informative and helped us understand the process
                easily and we are very much going to use their services in the
                future for sure!
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #5f6bc1">D</div>
                <div class="info">
                  <div class="name">Darryn M.</div>
                  <div class="title">New Beith, QLD</div>
                </div>
              </div>
              <div class="desc">
                sellingyourcar.com.au was professional and great to deal with
                through the entire process of selling my car. Thank you! I'll
                definitely be using you in the future and also referring you to
                others.
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #6d9e42">W</div>
                <div class="info">
                  <div class="name">Wayne K.</div>
                  <div class="title">Port Lincoln, SA</div>
                </div>
              </div>
              <div class="desc">
                Would recommend sellingyourcar.com.au 100%! At first I was
                skeptical, thought this is just too easy to be legit ..Got
                vehicle appraised, sold and paid off within a weeks time ! Don't
                hesitate if you are considering selling your vehicle !
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #e9497d">T</div>
                <div class="info">
                  <div class="name">Tshering L.</div>
                  <div class="title">Mount Ommaney, QLD</div>
                </div>
              </div>
              <div class="desc">
                Had a great experience with sellingyourcar.com.au. It was nice
                not having to deal with a car dealership and completed
                everything from the comfort of my home. They purchased my Tesla
                for the amount they originally quoted. Everyone I worked with
                was very responsive.
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #5f6bc1">M</div>
                <div class="info">
                  <div class="name">Moffatt A.</div>
                  <div class="title">Parklands, WA·</div>
                </div>
              </div>
              <div class="desc">
                sellingyourcar.com.au is PHENOMENAL. I called on a Thursday, had
                someone look at my car on Friday, and had money in my account on
                Monday. The process was fast, hassle free, and they quoted me
                more than anyone else for my vehicle. Definitely recommend using
                them.
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>
        <!--分页器。如果放置在swiper外面，需要自定义样式。-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperOptions: {
        loop: true,
        autoplay: false,
        slidesPerView: "auto",
        spaceBetween: 80,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          bulletElement: "li",
        },
      },
    };
  },
};
</script>

<style scoped lang="less">
.recommend {
  width: 100%;
  background: url("~@/assets/images/banner4.png") no-repeat;
  background-size: cover;
  background-position: center;
  .warpper {
    padding: 120px 0;
    .swiper {
      width: 100%;
      .swiper-side {
        width: 1200px;
        .item {
          margin: 0 auto;
          width: 1200px;
          height: 320px;
          padding: 40px;
          border-radius: 8px;
          background: #fff;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .top {
            display: flex;
            align-items: center;
            .avatar {
              width: 52px;
              height: 52px;
              border-radius: 50%;
              text-align: center;
              line-height: 52px;
              font-family: Ubuntu-Bold, Ubuntu;
              font-size: 24px;
              color: #fff;
            }
            .info {
              margin-left: 20px;
              .name {
                font-family: Ubuntu-Bold, Ubuntu;
                font-size: 16px;
                line-height: 16px;
                font-weight: 700;
                color: #212121;
              }
              .title {
                font-family: Ubuntu-Regular, Ubuntu;
                font-size: 16px;
                line-height: 16px;
                font-weight: 700;
                color: rgba(0, 0, 0, 0.5);
                margin-top: 12px;
              }
            }
          }
          .desc {
            font-family: Ubuntu-Regular, Ubuntu;
            font-size: 16px;
            font-weight: 700;
            color: #212121;
            line-height: 32px;
            margin-top: 30px;
          }
          .rank {
            height: 32px;
            img {
              height: 100%;
            }
          }
        }
      }
      .swiper-pagination {
        width: 100%;
        height: 24px;
        /deep/ .swiper-pagination-bullet {
          background: rgba(255, 255, 255, 0.8);
          margin: 0 5px;
        }
        /deep/ .swiper-pagination-bullet-active {
          background: #fff;
        }
      }
    }
  }
}
@media only screen and (max-width: 690px) {
  .recommend {
    // height: 2120px;
    width: 100%;
    .warpper {
      padding: 40px 0;
      .swiper {
        .swiper-side {
          width: 375px;
          .item {
            width: 450px;
            height: 175px;
            padding: 10px;
            .top {
              .avatar {
                width: 24px;
                height: 24px;
                line-height: 24px;
                font-size: 16px;
              }
              .info {
                margin-left: 12px;
                .name {
                  font-size: 12px;
                  line-height: 14px;
                }
                .title {
                  margin-top: 4px;
                  font-size: 10px;
                  line-height: 12px;
                }
              }
            }
            .desc {
              margin-top: 10px;
              font-size: 10px;
              font-family: Ubuntu-Regular, Ubuntu;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.66);
              line-height: 12px;
            }
            .rank {
              height: 16px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1050px) and (min-width: 690px) {
  .recommend {
    // height: 2120px;
    width: 100%;
    .warpper {
      padding: 40px 0;
      .swiper {
        .swiper-side {
          width: 375px;
          .item {
            width: 350px;
            height: 175px;
            padding: 10px;
            .top {
              .avatar {
                width: 24px;
                height: 24px;
                line-height: 24px;
                font-size: 16px;
              }
              .info {
                margin-left: 12px;
                .name {
                  font-size: 12px;
                  line-height: 14px;
                }
                .title {
                  margin-top: 4px;
                  font-size: 10px;
                  line-height: 12px;
                }
              }
            }
            .desc {
              margin-top: 10px;
              font-size: 10px;
              font-family: Ubuntu-Regular, Ubuntu;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.66);
              line-height: 12px;
            }
            .rank {
              height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
