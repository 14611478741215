<template>
  <div class="banner">
    <div class="container">
      <div class="banner-warpper">
        <div class="form">
          <div class="banner_title">
            Discover The Simplest Way To Sell Your Car In {{ pageName }}.
          </div>
          <div class="form-desc">
            Get paid faster without any fuss or hassle.
          </div>
          <div class="form-info">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
              <div class="form-item">
                <el-form-item prop="kilometres">
                  <el-input v-model="ruleForm.kilometres" type="text" placeholder="Kilometres driven*"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <el-form-item prop="year">
                  <el-input v-model="ruleForm.year" type="text" placeholder="Year manufactured*"></el-input>
                </el-form-item>
              </div>
            </el-form>
            <div class="subimit" @click="handleContinue">Get Instant Offer</div>
            <div class="form-desc"
              style="text-align: center; font-size: 14px; margin-top: 8px;margin-bottom: 8px;color: #999;">OR
            </div>
            <div class="subimit new-b" style="margin-top: 0;" @click="handleContinue2">Finance Your Next Car</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fromRules from "@/utils/fromRules";
export default {
  props: {
    pageName: {
      default: "Australia",
    },
  },
  data() {
    return {
      ruleForm: {
        kilometres: "",
        year: "",
      },
      rules: {
        kilometres: [
          {
            required: true,
            validator: fromRules.validateNumber,
            message: "Please enter the correct information",
            trigger: "blur",
          },
        ],
        year: [
          {
            required: true,
            validator: fromRules.validateNumber,
            message: "Please enter the correct information",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handleContinue() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.kilometres >= 150000 || this.ruleForm.year < 2012) {
            this.$alert(
              "*No older than 2012 with less than 150,000kms",
              "Tips",
              {
                confirmButtonText: "Confirm",
                confirmButtonClass: "confirm-button",
                customClass: "my-message",
              }
            );
          } else if (this.ruleForm.year > new Date().getFullYear()) {
            this.$alert(
              `*No more than ${new Date().getFullYear()} with less than 150,000kms`,
              "Tips",
              {
                confirmButtonText: "Confirm",
                confirmButtonClass: "confirm-button",
                customClass: "my-message",
              }
            );
          } else {
            sessionStorage.setItem("startInfo", JSON.stringify(this.ruleForm));
            this.$router.push("/start-sell-your-car");
          }
        }
      });
    },
    handleContinue2() {
      window.open('https://www.carsfinance.com.au')
    }
  },
};
</script>

<style lang="less">
@myColor: #0c61b9;

.confirm-button {
  background-color: @myColor !important;
  border-color: @myColor !important;
  // font-weight: b;
  font-family: Ubuntu-Regular !important;
}

.my-message {
  width: auto !important;
  font-family: Ubuntu-Regular !important;
}
</style>
<style scoped lang="less">
.banner {
  width: 100%;
  height: 969px;
  background: url("~@/assets/images/banner_1.png") no-repeat;
  background-size: cover;
  background-position: center;

  .banner-warpper {
    // display: flex;
    // align-items: center;
    padding-top: 160px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    // .banner_title {
    //   width: 800px;
    //   font-size: 40px;
    //   font-family: Ubuntu-Bold, Ubuntu;
    //   font-weight: bold;
    //   color: #ffffff;
    //   line-height: 47px;
    // }
    .icons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 84px;
      width: 800px;

      .item {
        display: flex;
        align-items: center;

        .icon {
          width: 87px;
          height: 87px;
          margin-right: 16px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .info {
          font-size: 16px;
          font-family: Ubuntu-Bold, Ubuntu;
          font-weight: bold;
          color: #ffffff;
          line-height: 24px;
        }
      }
    }

    .form {
      margin-top: 100px;
      padding: 20px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.11);
      border-radius: 10px 10px 10px 10px;
      width: 400px;

      // .form-title {
      //   display: flex;
      //   align-items: center;
      //   .left {
      //     width: 80px;
      //     height: 80px;
      //     img {
      //       width: 80px;
      //       height: 80px;
      //     }
      //   }
      //   .right {
      //     margin-left: 13px;
      //     font-size: 24px;
      //     font-family: Ubuntu-Bold, Ubuntu;
      //     font-weight: bold;
      //     line-height: 28px;
      //     background: linear-gradient(180deg, #0b5cd7 0%, #55ccff 100%);
      //     -webkit-background-clip: text;
      //     -webkit-text-fill-color: transparent;
      //   }
      // }
      .banner_title {
        font-size: 24px;
        font-family: Ubuntu-Bold, Ubuntu;
        font-weight: bold;
        color: #222222;
        line-height: 28px;
      }

      .form-desc {
        font-size: 16px;
        font-family: Ubuntu-Regular, Ubuntu;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.72);
        line-height: 19px;
        margin-top: 24px;
      }

      .form-info {
        width: 100%;
        font-family: Ubuntu-Regular;
        margin-top: 40px;

        .form-item {
          width: 100%;
          margin-bottom: 16px;

          /deep/ .el-input__inner {
            -web-kit-appearance: none;
            -moz-appearance: none;
            outline: 0;
            width: 100%;
            padding: 20px;
            background: #f5f5f5;
            border: 1px solid transparent;
            color: #212121;
            border-radius: 4px;
            font-family: Ubuntu-Regular;

            &:focus {
              border: 1px solid #0c61b9;
            }
          }
        }

        .subimit {
          width: 100%;
          padding: 15px;
          box-sizing: border-box;
          background: #0c61b9;
          text-align: center;
          font-family: Ubuntu-Bold;
          font-weight: 600;
          font-size: 14px;
          border-radius: 6px;
          line-height: 16px;
          margin-top: 40px;
          user-select: none;
          cursor: pointer;
          transition: all 0.3s;
          color: #fff;

          &:hover {
            transform: translateY(-5px);
            box-shadow: 0 20px 38px rgba(0, 0, 0, 0.2);
          }
        }

        .new-b {
          background: #00a2e8;
          margin-top: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 690px) {
  .banner {
    height: 560px;
    background-size: auto 400px;
    background-position: top;

    .banner-warpper {
      // align-items: flex-start;
      padding-top: 104px;

      .banner_title {
        width: 295px;
        font-size: 24px;
        // width: 100%;
        line-height: 28px;
      }

      .icons {
        width: 295px;
        margin-top: 40px;

        .item {
          flex-direction: column;

          .icon {
            width: 48px;
            height: 48px;
            margin-right: 0;
          }

          .info {
            font-size: 12px;
            line-height: 14px;
            margin-top: 16px;
          }
        }
      }

      .form {
        width: 335px;
        margin-top: -10px;
        // transform: translateY(300px);
        box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.12);

        .form-title {
          .right {
            font-size: 20px;
            line-height: 23px;
          }
        }

        .form-desc {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1050px) and (min-width: 690px) {
  .banner {
    height: 560px;
    background-size: auto 400px;
    background-position: top;

    .banner-warpper {
      align-items: flex-start;

      .form {
        margin: 0 auto;
        width: 335px;
        transform: translateY(300px);

        .form-title {
          font-size: 24px;
          line-height: 28px;
        }

        .form-desc {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
}
</style>
