<template>
  <div class="banner-2">
    <div class="container">
      <div class="warpper">
        <div class="wa-title">Why Choose Us?</div>
        <div class="wa-desc">
          There’s plenty of reasons why Australians choose Selling Your Car when
          they need to trade their vehicles. We are a trusted, dedicated, and
          rewarding service that puts our client’s satisfaction above all else.
          Here are some of the most shared reasons why we benefit you when
          selling your car:
        </div>
        <div class="item-warpper">
          <div class="item">
            <img src="~@/assets/images/qian.png" alt="" />
            <div class="info">
              <div class="title">
                We’re the quickest and most efficient way to sell your car.
              </div>
              <div class="desc">
                Nobody likes waiting around for the right price and receiving
                their money. We expedite the process using our expert team to
                return your valuation, agree on a price, and send your money as
                quickly as possible.
              </div>
            </div>
          </div>
          <div class="item">
            <img src="~@/assets/images/ren.png" alt="" />
            <div class="info">
              <div class="title">Get the price your vehicle is worth.</div>
              <div class="desc">
                You won’t find any better deal for your car than at Selling Your
                Car. We want to make sure that our clients find themselves
                satisfied with their vehicle valuation.
              </div>
            </div>
          </div>
          <div class="item">
            <img src="~@/assets/images/shou.png" alt="" />
            <div class="info">
              <div class="title">
                Our service is safe, secure, and committed to car owners.
              </div>
              <div class="desc">
                We want you to feel comfortable while selling your used cars.
                Our service features accreditation and verification by all the
                relevant authorities to give clients peace of mind when trading
                their vehicles.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="less">
.banner-2 {
  width: 100%;
  height: 960px;
  background: url("~@/assets/images/banner_2.png") no-repeat;
  background-size: cover;
  background-position: center;
  .warpper {
    padding: 120px 0;
    box-sizing: border-box;
    .wa-title {
      font-size: 40px;
      font-family: Ubuntu-Bold, Ubuntu;
      font-weight: bold;
      color: #ffffff;
      line-height: 47px;
      text-align: center;
    }
    .wa-desc {
      font-size: 20px;
      font-family: Ubuntu-Regular, Ubuntu;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      margin-top: 20px;
      text-align: center;
      margin-bottom: 40px;
    }
    .item {
      padding: 40px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 120px;
        height: 120px;
      }
      .info {
        margin-left: 16px;
        .title {
          font-size: 24px;
          font-family: Ubuntu-Bold, Ubuntu;
          font-weight: bold;
          color: #ffffff;
          line-height: 28px;
        }
        .desc {
          font-size: 16px;
          font-family: Ubuntu-Regular, Ubuntu;
          font-weight: 400;
          color: #ffffff;
          line-height: 19px;
          margin-top: 20px;
        }
      }
      &:nth-child(2) {
        margin-left: 0px;
        margin-right: 16px;
        flex-direction: row-reverse;
      }
    }
  }
}
@media only screen and (max-width: 690px) {
  .banner-2 {
    height: 860px;
    .warpper {
      padding: 40px 0;
      .wa-title {
        font-size: 24px;
        line-height: 28px;
      }
      .wa-desc {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 20px;
      }
      .item {
        padding: 0;
        flex-direction: column;
        margin-top: 40px;
        img {
          width: 80px;
          height: 80px;
        }
        .info {
          margin-left: 0px;
          .title {
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            margin-top: 8px;
          }
          .desc {
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            margin-top: 8px;
          }
        }
        &:nth-child(2) {
          flex-direction: column;
          margin-right: 0px;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 1050px) and (min-width: 690px) {
  .banner-2 {
    height: 700px;
    .warpper {
      padding: 40px 0;
      .wa-title {
        font-size: 24px;
        line-height: 28px;
      }
      .wa-desc {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 20px;
      }
      .item {
        padding: 0;
        flex-direction: column;
        margin-top: 40px;
        img {
          width: 80px;
          height: 80px;
        }
        .info {
          margin-left: 0px;
          .title {
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            margin-top: 8px;
          }
          .desc {
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            margin-top: 8px;
          }
        }
        &:nth-child(2) {
          flex-direction: column;
          margin-right: 0px;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
