<template>
  <div class="pay">
    <div class="container">
      <div class="pay_title">
        <div class="left">
          <img src="~@/assets/images/form_logo.png" alt="" />
        </div>
        <div class="info">We Can Pay Your Car Today！</div>
      </div>
      <div class="icons">
        <div class="item">
          <div class="icon">
            <img src="~@/assets/images/f_icon1.png" alt="" />
          </div>
          <div class="info">
            <div class="top">SAME DAY</div>
            <div class="bottom">Call Back</div>
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img src="~@/assets/images/f_icon2.png" alt="" />
          </div>
          <div class="info">
            <div class="top">SAME DAY</div>
            <div class="bottom">Inspection</div>
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img src="~@/assets/images/f_icon3.png" alt="" />
          </div>
          <div class="info">
            <div class="top">SAME DAY</div>
            <div class="bottom">Payment</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.pay {
  padding: 120px 0;
  background: #edf6ff;
  box-sizing: border-box;
  .pay_title {
    display: flex;
    align-items: center;
    justify-content: center;
    .left {
      width: 120px;
      height: 120px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info {
      font-size: 40px;
      font-family: Ubuntu-Bold, Ubuntu;
      font-weight: bold;
      color: #062748;
      line-height: 47px;
      margin-left: 10px;
    }
  }
  .icons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 68px;
    // width: 800px;
    .item {
      display: flex;
      align-items: center;
      .icon {
        width: 120px;
        height: 120px;
        margin-right: 17px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info {
        font-size: 24px;
        font-family: Ubuntu-Bold, Ubuntu;
        font-weight: bold;
        color: #222222;
        line-height: 28px;
      }
    }
  }
}
@media only screen and (max-width: 690px) {
  .pay {
    padding: 40px 0;
    .pay_title {
      .left {
        width: 80px;
        height: 80px;
      }
      .info {
        width: 195px;
        height: 46px;
        font-size: 20px;
        font-family: Ubuntu-Bold, Ubuntu;
        font-weight: bold;
        line-height: 23px;
        background: linear-gradient(180deg, #0b5cd7 0%, #55ccff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-left: 10px;
      }
    }
    .icons {
      justify-content: space-between;
      margin-top: 20px;
      .item {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .icon {
          width: 48px;
          height: 48px;
          margin-right: 0;
        }
        .info {
          font-size: 12px;
          font-family: Ubuntu-Bold, Ubuntu;
          font-weight: bold;
          color: #062748;
          line-height: 14px;
          margin-top: 16px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1050px) and (min-width: 690px) {
  .pay {
    padding: 40px 0;
    .pay_title {
      .left {
        width: 80px;
        height: 80px;
      }
      .info {
        width: 195px;
        height: 46px;
        font-size: 20px;
        font-family: Ubuntu-Bold, Ubuntu;
        font-weight: bold;
        line-height: 23px;
        background: linear-gradient(180deg, #0b5cd7 0%, #55ccff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-left: 10px;
      }
    }
    .icons {
      justify-content: space-between;
      margin-top: 20px;
      .item {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .icon {
          width: 48px;
          height: 48px;
          margin-right: 0;
        }
        .info {
          font-size: 12px;
          font-family: Ubuntu-Bold, Ubuntu;
          font-weight: bold;
          color: #062748;
          line-height: 14px;
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
